import React, {useEffect, useState} from "react";
import ProductItem from "../components/ProductItem";
import Header from "../components/Header";

const Home = () => {


    const [search, setSearch] = useState('');
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilterProducts] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        var raw = JSON.stringify({
            "language_code": "tr",
            "location": {},
            "vendor_id": "xghk",
            "include_fields": [
                "feed"
            ],
            "include_component_types": [
                "products"
            ],
            "platform": "web",
            "dynamic_pricing": 0,
            "is_darkstore": true,
            "brand": "yemeksepeti",
            "country_code": "tr",
            "limit": 1000,
            "offset": 0,
            "product_tag": "campaign-banner-993d81c7-0d86-4fc6-942c-1291c6da149d"
        });

        var requestOptions = {
            method: 'POST',
            body: raw
        };

        fetch("https://disco.deliveryhero.io/product-listing/api/v1/components", requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoading(false);
                setProducts(result.feed.items[0].items);
                setFilterProducts(result.feed.items[0].items);

            })
            .catch(error => console.log('error', error));

    }, []);


    const filterItem = (e) => {
        const val = e.target.value;
        if (val.length == 0) {
            setFilterProducts(products)
        }
        setSearch(val);
        const result = products.filter(product => {
            return product.name.toLowerCase('TR').indexOf(search.toLowerCase('TR')) !== -1;
        });
        setFilterProducts(result)

    }

    return (
        <>
            <div className="container mx-auto">

                <input
                    className="h-12 border-[1px] p-2 border-gray-200 w-full focus:outline-0 mt-14"
                    type="text"
                    placeholder="Ara..."
                    value={search}
                    onChange={(e) => filterItem(e)}/>

                <section>
                    <div className="mx-auto max-w-screen-xl px-4 py-8">
                        <div className="mt-8 grid grid-cols-2 gap-x-4 gap-y-8 lg:grid-cols-4">


                            {loading ? <div className="text-center">Loading...</div> : filteredProducts.map((item) => (
                                <ProductItem key={item.id} product={item}/>
                            ))}

                        </div>
                    </div>
                </section>


            </div>
        </>
    );
}
export default Home;