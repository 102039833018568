const ProductItem = (item) => {

    let orginalPrice = item.product.original_price;
    let price = item.product.original_price;

    let yuzde = '50';

    return (
        <div className="relative block border border-gray-100">
            <img
                alt="Toy"
                src={item.product.image_url}
                className="h-56 w-full object-contain"
            />

            <div className="p-6">
                <del className="text-xs font-bold text-red-600">
                    ₺ {item.product.original_price}
                </del>
                <p className="text-sm font-medium text-gray-600">
                    ₺ {item.product.price}
                </p>
                <span className="bg-yellow-300 text-black rounded p-1 text-xs float-right">
                    % {yuzde}
                </span>

                <h5 className="mt-1 text-lg font-bold">
                    {item.product.name}
                </h5>

                <button
                    name="add"
                    type="button"
                    className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4"
                >
                    <span className="text-sm font-medium"> Ürüne Git </span>

                    <svg
                        className="ml-1.5 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                        />
                    </svg>
                </button>
            </div>
        </div>
    )

}
export default ProductItem;