const OtherDiscount = () => {

    return (
        <>
            <div>
                Test bla bal bla bla
            </div>
        </>
    )

}
export default OtherDiscount;