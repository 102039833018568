import Header from "./components/Header";
import Home from "./pages/Home";
import OtherDiscount from "./pages/OtherDiscount";
import {Routes, Route} from "react-router-dom";


const App = () => {
    return (
        <>
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/other-discount" element={<OtherDiscount/>}/>
            </Routes>
        </>
    )
}
export default App;